
.text-custom-primary {
  color: #387DFF !important;
}
.bg-custom-primary {
  background: linear-gradient(90deg, #387DFF -4.49%, #004BD6 104.49%) !important;
}
.text-custom-secondary {
  color: #F07672 !important;
}
.bg-custom-secondary {
  background: linear-gradient(92.21deg, #FA9347 5.16%, #F07672 99.12%) !important;
}

.cursorPointer:hover {
  cursor: pointer;
}
.cursorZoomIn:hover {
  cursor: zoom-in;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #F1F1F1;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #888888;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.spinner{
  width: 25px;
  height: 25px;
  border-bottom: 3px dotted #2E75F9;
  border-left: 2px dotted #2E75F9;
  border-right: 1px solid #2E75F9;
  border-top: 3px dotted #2E75F9;
}